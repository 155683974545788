'use client';

export default function Error({
    error,
    reset,
}: {
    error: Error;
    reset: () => void;
}) {
    const t = (key: string) => key
    
    return (
        <section className={'o-content-section'}>
            <div className={'o-page-wrap  o-page-wrap--large'}>
                <div className={'o-component-wrapper'}>
                    <p><strong>{error.message}</strong></p>
                    <button onClick={() => reset()}>{t('reset')}</button>
                </div>
            </div>
        </section>

    );
}
